import React from "react";
import { Link } from "gatsby";
import saleProcess from "../images/crm-proces-sprzedazy.png";
import tasks from "../images/crm-zadania.png";
import kb from "../images/baza-wiedzy.png";
import uptime from "../images/crm-online-247.png";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Dots from "../components/dots";
import { FaCheck } from "react-icons/fa";
import Hero from "../components/hero";
import styles from "./index.module.css";
import Columns from "../components/columns";
import Container from "../components/container";
import heroImage from "../images/Prosty-CRM-online-dla-malych-firm.jpg";
import TryButton from "../components/TryButton";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import {
  FaBinoculars,
  FaPlus,
  FaMagnet,
  FaFilter,
  FaCalendar,
  FaBook,
  FaCrosshairs,
  FaWrench,
  FaChartLine, 
  FaUsers, 
  FaSearch, 
  FaClock,
  FaKey,
  FaCubes, 
  FaCogs,
  FaComments,
  FaExchangeAlt,
  FaCheckSquare,
  FaListOl

} from "react-icons/fa";


export const query = graphql`
  query {
    imgLaptopITel: file(relativePath: { eq: "laptel.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgHero: file(
      relativePath: {
        eq: "nowy-laptop1.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Prosty CRM Online – Sprzedawaj Więcej"
      description="Prosty CRM Online dla małych i średnich firm ✅ Szanse sprzedaży. Baza kontrahentów. Zadania. Raporty. ✅ Wypróbuj za darmo w kilka sekund!"
    />
    <Hero>
      <div
        style={{
          marginTop: 100
        }}
      >
        <h1
          style={{
            fontSize: 48,
            marginBottom: 20
          }}
        > <Link
              style={{ textDecoration: "none", color: "white" }}
              to="https://synergiuscrm.pl"
            >Przejdź na <strong>Synergius CRM</strong> – nowocześniejsze rozwiązanie
            </Link>
        </h1>
        <h4>
          <p>
            Jeśli zainteresował Cię system Quick CRM, zachęcamy do zapoznania się z <Link style={{ textDecoration: "none", color: "red" }} to="https://synergiuscrm.pl"><strong>Synergius CRM</strong> </Link><br />
            – nowoczesnym, regularnie aktualizowanym rozwiązaniem, które oferuje jeszcze więcej możliwości. <br />
            <br />
            System <strong>Quick CRM wkrótce zostanie wycofany</strong>.
          </p>
        </h4>
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: "1000px"
        }}
      >
        <Img
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 1000
          }}
          fluid={data.imgHero.childImageSharp.fluid}
          alt="Prosty CRM online System CRM Zarządzanie relacjami z klientami"
        />
      </div>
    </Hero>
<Container className="container container--text">
 <div className="text-answers">
         <div className="text-block" style={{
            padding: 20
          }}>
        <center><Dots color="#5472d2" /></center>
          <h3>Dlaczego warto wybrać QuickCRM?</h3>
          <p>
            <strong>To prosty CRM online</strong>, który wesprze proces pozyskiwania 
            i obsługi klienta. Pomoże zaplanować pracę, zarządzać zadaniami pracowników 
            i&nbsp;zapanować nad współpracą z&nbsp;każdym Twoim klientem.
          </p>
          <ul className={styles.featureList}>
            <li>
              <FaCheck /> Planowanie kontaktów z&nbsp;potencjalnymi klientami.
            </li>
            <li>
              <FaCheck /> Wsparcie pracownika w&nbsp;procesie sprzedaży.
            </li>
            <li>
              <FaCheck /> Porządkowanie zebranych informacji i&nbsp;ich analiza.
            </li>
            <li>
              <FaCheck /> Dostęp do historii współpracy z&nbsp;każdym klientem.
            </li>
          </ul>
        </div>
  <div className="text-block" style={{
            padding: 20
          }}>
          <Img
          style={{
            maxWidth: 1200,
            margin: "0 auto"
          }}
          fluid={data.imgLaptopITel.childImageSharp.fluid}
          alt="crm laptop i telefon"
        />
          
          
        </div>
</div>
</Container>

<section
      style={{
        backgroundColor: "rgba(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container">
        <div
          style={{
            flex: 2
          }}
          className="container__text"
        >
       
          <div className={styles.textWrapper}>
            <center><h3>Co nas wyróżnia?</h3></center>
            <br />
          </div>
          <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaClock size="45px" />}
          content="Szybkie wdrożenie"
        />
        <BenefitBlock
          icon={<FaCogs size="45px" />}
          content="Elastyczność"
        />
        <BenefitBlock
          icon={<FaComments  size="45px" />}
          content="Wsparcie i&nbsp;materiały edukacyjne"
        />
        <BenefitBlock
          icon={<FaPlus size="45px" />}
          content="Możliwości rozwojowe"
        />
      </BenefitBlockWrapper>
       </div>
       </div>
    </section>

    <section
      className={styles.darkSection}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage})`,
        backgroundColor: "rgba(0,0,0,0.6)",
        color: "#fff",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="container">
        <div
          style={{
            flex: 2
          }}
          className="container__text"
        >
          <Dots color="#ffffff" />
          <h3>Poznaj możliwości QuickCRM</h3>
          <div className={styles.textWrapper}>
            <p>
              <strong>QuickCRM</strong> to narzędzie skierowane do firm 
              poszukujących usprawnień biznesowych w&nbsp;rozwiązaniach informatycznych. 
              Prosty CRM ma za zadanie pomagać w działaniach sprzedażowych oraz 
              prowadzić do budowania trwałych relacji z&nbsp;klientami.
              </p>
<p>
Sprawdź możliwości systemu CRM i dowiedz się jak może Ci pomóc. 
</p>
<p>
              Przekonaj się, jak łatwo możesz pozyskiwać klientów dla swojej
              firmy.
            </p>
          </div>
 
        <br/ >
        <br/ >       </div>
        <div
          style={{
            flex: 4
          }}
          className={styles.container__functions}
        >
          <div className="functioncard__group">
            <div className={styles.functioncard}>
              <img src={saleProcess} alt="prosty crm proces sprzedaży" />
              <h3>Proces sprzedaży</h3>
              <p>
                CRM pozwala tworzyć własne kampanie i&nbsp;zarządzać szansami
                sprzedaży.
              </p>
            </div>
            <div style={{ marginTop: 80 }} className={styles.functioncard}>
              <img src={kb} alt="prosty crm baza wiedzy" />
              <h3>Baza wiedzy</h3>
              <p>
                CRM zbiera i&nbsp;porządkuje dane Twoich klientów oraz informacje 
                o&nbsp;waszej współpracy i&nbsp;jej historii.
              </p>
            </div>
          </div>
          <div className="functioncard__group">
            <div style={{ marginTop: 40 }} className={styles.functioncard}>
              <img src={tasks} alt="prosty crm zadania" />
              <h3>Zadania pracowników</h3>
              <p>
                Możliwość zarządzania zadaniami w&nbsp;interaktywnym kalendarzu. 
                Wiązanie zadań z&nbsp;klientami i&nbsp;szansami sprzedaży.
              </p>
            </div>
            <div style={{ marginTop: 80 }} className={styles.functioncard}>
              <img src={uptime} alt="prosty crm dostępność" />
              <h3>Dostępność 24/7</h3>
              <p>
                Nie wymaga instalacji. CRM online jest dostępny na każdym urządzeniu 
                z&nbsp;Internetem w&nbsp;każdym miejscu na Ziemi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      style={{
        backgroundColor: "#fff",
        color: "rgb(48, 48, 48)"
      }}
    >
      <div className="container">
        <div
          style={{
            flex: 2
          }}
          className="container__text"
        >
          <Dots color="#5472d2" />
          <div className={styles.textWrapper}>
            <h3>Pozwól Twojej firmie na rozwój</h3>
            <p>QuickCRM to system, który wesprze rozwój Twojego przedsiębiorstwa</p>
          </div>

        </div>
        <div
          style={{
            flex: 4
          }}
          className="container__grow"
        >
          <div className={styles.termsCard}>
            <center><h2>Bez limitów</h2></center>
            <p>Działaj bez limitu kontrahentów, zadań czy szans sprzedaży.</p>
          </div>
          <div className={styles.termsCard}>
            <center><h2>Brak ograniczeń</h2></center>
            <p>Samodzielnie określaj etapy kampanii i&nbsp;rodzaje zdarzeń.</p>
          </div>
          <div className={styles.termsCard}>
            <center><h2>Pełna kontrola</h2></center>
           <p>
              Monitoruj najważniejsze wskaźniki dotyczące sprzedaży.
            </p>
          </div>
          <div className={styles.termsCard}>
            <center><h2>Rozwój</h2></center>
            <p>
              Rozwijaj swoje przedsiębiorstwo oraz posiadany system.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: 0, marginBottom: 0, padding: 0 }}
        className="container"
      >
      </div>
    </section>

    <section
      className={styles.pricingSection}
      style={{
        backgroundColor: "rgba(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        padding: 40
      }}
    >
    <div className="container container--text">
        <center>
          <Dots color="#666666" />
        </center>
        <h3>CRM online odpowiedni dla Twojego przedsiębiorstwa</h3>
        <div className="pricing-model">
                        
          <div
            style={{
              backgroundColor: "rgb(165, 185, 201)"
            }}
          >

            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-malej-firmy/"
            >

              <h2>PROSTY CRM</h2>
              <h4>dla małej firmy</h4>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "rgb(85, 155, 201)"
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-sredniej-firmy/"
            >
              <h2>PROSTY CRM</h2>
              <h4>dla średniej firmy</h4>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "rgb(20, 128, 201)"
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-duzej-firmy/"
            >
              <h2>CRM</h2>
              <h4>dla dużej firmy</h4>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section
      style={{
        backgroundColor: "#fff",
        color: "rgb(48, 48, 48)"
      }}
    >
      <Container className="container container--text">
        <h3 style={{
          paddingBottom:10,
          paddngTop:10
        }}><strong>Wybierz prosty CRM o&nbsp;wielu możliwościach</strong></h3>
        
        <div className="text-answers">
          <div className="text-block">
          <center><h4>Sprawdź jak CRM zwiększy sprzedaż Twojej firmy</h4></center>
            <p>
                Prosty CRM pozwala zapanować nad skomplikowanymi procesami
                sprzedażowymi, klasyfikuje zadania oraz porządkuje informacje 
                o&nbsp;klientach. Obecność CRM online sprawia, że istnieje pewność, co do
                działań, które należy wykonać, aby doprowadzić sprzedaż do końca. 
                Systemowe raporty pozwolają na analizę działań z&nbsp;przeszłości i&nbsp;wyciąganie
              z nich wniosków. <strong>Prosty CRM online jakim jest QuickCRM to
               narzędzie do optymalizacji
              sprzedaży i efektywnej obsługi klienta.</strong>
            </p>
          </div>
          <div className="text-block">
          <center><h4>Wsparcie Twojego procesu sprzedażowego</h4></center>

<p>
<ul>
<li>Tworzenie kampanii sprzedażowych z podziałem na dowolne etapy.</li>
<li> Widok <Link to="/sprzedaz-w-crm/">szans sprzedaży</Link> pozwalający
              trzymać rękę na pulsie i monitorować ich liczbę oraz prognozowaną
              wartość.</li>
<li>Ikony informujące o konieczności zaplanowania lub wykonania zadania powiązanego z szansą.</li>
<li>Wszystkie informacje potrzebne do zamknięcia sprzedaży w jednym miejscu.</li>
</ul>
            </p>
          </div>
        </div>

      </Container>
    </section>
  </Layout>
);

export default IndexPage;